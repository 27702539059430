import React from "react";
import {
  Text,
  Anchor,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import classes from "./Legal.module.css";
import I18n from "../../i18n.js";
import { LegalModal } from "../LegalModal/LegalModal";
import { useLocation } from "react-router-dom";

interface LegalProps {
  id: string;
  index: number;
}

export function Legal({
  id, index
}: LegalProps) {
  const location = useLocation();  
  const [opened ,{ open, close }] = useDisclosure(false);
  React.useEffect(() => {
    if (location.pathname === "/"+id) {
      open();
    }
  }, [location.pathname, open]);
  return (
    <>
      <LegalModal id={id} close={close} opened={opened} />
      
      <Anchor
        key={index}
        onClick={open}
        className={`${classes.link}`}
        style={{
          textDecoration: "underline"
        }}>
        <Text>{I18n.t(`legal.${id}.heading`)}</Text>
      </Anchor>
    </>
  );
}
