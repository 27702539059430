import {
  Button,
  Container,
  Group,
  Space,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { Trans } from "react-i18next";
import classes from "./HeroContent.module.css";
import { Link } from "react-scroll";

export function HeroContent() {

  const theme = useMantineTheme();
  return (
    <Container className={classes.mainContainer} style={{ maxWidth: theme.breakpoints.lg, width: '100%' }}>
      <div className={classes.content}>
        <Title className={classes.title}>
          <Text
            fs="italic"
            inherit
            variant="gradient"
            gradient={{ from: "orange", to: "#FF7F2A" }}
          >
            <Trans i18nKey="title.toolkit" />
          </Text>
          <Text component="span" inherit>
            <Trans i18nKey="title.creator" />
          </Text>
        </Title>
        <Text className={classes.description} mt={30}>
          <Trans i18nKey="title.advertisement-text" />
        </Text>
        <Space h={"xl"}/>
        <Group>
          <Link to={"features"}>
            <Button
              variant="default"
              size="lg"
              
              className={classes.control}
            >
              Features
            </Button>
          </Link>
          <Link to={"pricing"}>
            <Button
              variant="gradient"
              gradient={{ from: "orange", to: "#FF7F2A" }}
              size="lg"
              className={classes.control}
            >
              <Trans i18nKey="title.download-buy" />
            </Button>
          </Link>
        </Group>
      </div>

      <Space h="xl" />
      <Space h="xl" />
      <Space h="xl" />
      <Space h="xl" />
      <Space h="xl" />
      <Space h="xl" />
      <Space h="xl" />
      <Space h="xl" />
      <Space h="xl" />
      <Space h="xl" />
      <Space h="xl" />
      <Space h="xl" />
      <Space h="xl" />
      <Space h="xl" />
    </Container>
  );
}
