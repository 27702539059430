import React from "react";
import {
  Text,
  Anchor,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import classes from "./Legal.module.css";
import I18n from "../../i18n.js";
import { useLocation } from "react-router-dom";
import { DownloadModal } from "../DownloadModal/DownloadModal";

interface DownloadProps {
  id: string;
  index: number;
  dotnet: string;
}

export function Download({
  id, index, dotnet
}: DownloadProps) {
  const location = useLocation();  
  const [opened ,{ open, close }] = useDisclosure(false);
  React.useEffect(() => {
    if (location.pathname === "/"+id || location.pathname === "/"+id+"/" || location.pathname === "/"+id+"/publish.html") {
      console.log(location.pathname);
      open();
    }
  }, [location.pathname, open]);
  return (
    <>
      <DownloadModal id={id} dotnet={dotnet} close={close} opened={opened} />
    </>
  );
}
