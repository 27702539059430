import {
  Card,
  Avatar,
  Image,
  Text,
  Button,
  Divider,
  Modal,
  Space,
  Title,
  Anchor,
  Container,
  Center,
  Grid,
  UnstyledButton,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { ReactNode } from "react";
import classes from "./DownloadModal.module.css";
import I18n from "../../i18n.js";
import parse from "html-react-parser";
import { IconDownload } from "@tabler/icons-react";

interface DownloadProps {
  id: string;
  dotnet: string;
  close: () => void;
  opened: boolean;
}

export function DownloadModal({
  id, dotnet, close, opened
}: DownloadProps) {
  const isMobile = useMediaQuery("(max-width: 50em)");
  return (
    <>
      <Modal
        size="lg"
        opened={opened}
        onClose={close}
        title={<Center><Title>{I18n.t(`download.${id}.header`)}</Title></Center>}
        fullScreen={isMobile}
      >
        <Divider />
        <Space h="md" />
        {parse(I18n.t(`download.${id}.text`)) != "" ? ( 
          <>
            <Text>{parse(I18n.t(`download.${id}.text`))}</Text>
            <Divider />
          </>
        ) : (
          <></>
        )}
        <Space h="md" />
        <Center>
          <Button variant="gradient" component="a" href={`https://acctv.de/${id}/ACCTV.application`}
                gradient={{ from: "orange", to: "#FF7F2A" }}
                size="md" className={classes.control}>
              {I18n.t("general.download")}<IconDownload />
          </Button>
        </Center>
      
        <Space h="md" />
        <Divider />
        <Space h="md" />
        <Center>
          <Text>{I18n.t("general.downloadDotNet")}{dotnet} Desktop Runtime: </Text><Space w="md" />
            <Button variant="gradient" component="a" href={`https://dotnet.microsoft.com/en-us/download/dotnet/thank-you/runtime-desktop-${dotnet}-windows-x64-installer`}
                gradient={{ from: "orange", to: "#FF7F2A" }}
                  size="md" className={classes.control}>
                <IconDownload />
            </Button>
        </Center>
        
      </Modal>
    </>
  );
}
