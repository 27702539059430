import {
  Container,
  Space,
  useMantineTheme,
} from "@mantine/core";
import { EditionComparison } from "../EditionComparison/EditionComparison";

export function CTABannerSingle() {
  const theme = useMantineTheme();
  return (
    <Container size="100%" style={{ maxWidth: theme.breakpoints.lg, width: '100%' }}>
      <Space h="xl" />
      <EditionComparison />
    </Container>
  );
}
