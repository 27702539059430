import React, { ReactNode } from 'react';
import accTvNormal from "../../media/Normal.png";
import accTvPro from "../../media/Pro.png";
import {
    Avatar,
    Image,
    Text,
    Button,
    Space,
    Stack,
    Title,
} from "@mantine/core";
import {
    IconBolt,
    IconProgressBolt,
    IconX,
    IconCheck,
} from "@tabler/icons-react";
import classes from "./EditionComparison.module.css";
import I18n from "../../i18n.js";
import { Trans } from "react-i18next";
import parse from "html-react-parser";

interface Edition {
    name: string;
    price: string;
    image: string;
    icon: ReactNode;
    features: boolean[];
}
interface FeatureTableProps {
    versions: Edition[];
    featuresList: string[];
}

const FeatureDiv: React.FC<FeatureTableProps> = ({ versions, featuresList }) => {
    return (
        <div className={classes.tableContainer}>
            <div  className={classes.tableHeader}>
                <div  className={`${classes.tableCell} ${classes.firstColumn}`}></div>
                {versions.map((version, index) => (
                    <div key={index} className={`${classes.tableCell} ${classes.stretchCell} ${classes.editionCell}`}>
                        <div className={classes.tableCardCell}>
                            <Image src={version.image} alt={version.name} className={classes.editionImage}/>
                            <Avatar
                                size={90}
                                radius={80}
                                mx="auto"
                                mt={-30}
                                className={classes.avatar}>
                                {version.icon}
                            </Avatar>
                            <Text ta="center" fz="lg" fw={500} mt="sm">
                                {version.name}
                            </Text>
                            <Text ta="center" fz="sm" c="dimmed">
                                {parse(I18n.t(version.price))}
                            </Text>
                        </div>
                    </div>
                ))}
            </div>
            {featuresList.map((feature, index) => (
                <div key={index} className={`${classes.tableRow}`}>
                    <div className={`${classes.tableCell} ${classes.firstColumn}`}>
                        <strong className={classes.hyphen}>{parse(I18n.t(feature))}</strong>
                    </div>
                    {versions.map((version, vIndex) => (
                        <div key={vIndex} className={`${classes.tableCell} ${classes.stretchCell} ${classes.comparisonCell} ${index === featuresList.length -1 ? classes.lastComparisonCell : ''}`}>
                            <Text ta="center">
                                {version.features[index] ? <IconCheck className={classes.checked} stroke={4} /> : <IconX className={classes.xed} stroke={4} />}
                            </Text>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};


    // Beispiel Verwendung der FeatureTable Komponente
export function EditionComparison() {
    const versions: Edition[] = [
        {
            name: I18n.t("cta.main.costs.cost1.name"),
            price: "cta.main.costs.cost1.price",
            features: [true, true, true, true, true, true, false, false, false, false],
            image: accTvNormal,
            icon: <IconProgressBolt size="50" color="#FF7F2A" />
        },
        {
            name: I18n.t("cta.main.costs.cost2.name"),
            price: "cta.main.costs.cost2.price",
            features: [true, true, true, true, true, true, true, true, true, true],
            image: accTvPro,
            icon: <IconBolt size="50" color="#FF7F2A" />
        }
    ];

    const featuresList: string[] = [
        "cta.main.features.feature1", 
        "cta.main.features.feature2", 
        "cta.main.features.feature3", 
        "cta.main.features.feature4", 
        "cta.main.features.feature5", 
        "cta.main.features.feature6", 
        "cta.main.features.feature7", 
        "cta.main.features.feature8", 
        "cta.main.features.feature9", 
        "cta.main.features.feature10", 
    ];
    return (
        <Stack align="center">
            <div>
                <Title className={classes.title}>
                    <Text
                        fs="italic"
                        component="span"
                        inherit
                        variant="gradient"
                        gradient={{ from: "orange", to: "#FF7F2A" }}>
                        <Trans i18nKey="cta.main.title" />
                    </Text>
                </Title>
                <Space h={"lg"} />
                <FeatureDiv versions={versions} featuresList={featuresList} />
                <Space h={"lg"} />
                <Button component="a" href={"https://dsc.gg/ACCTV"} color={"#FF7F2A"} fullWidth className={classes.hyphen} style={{padding: '10px', height: 'unset'}}>
                    <Trans i18nKey="cta.main.checkout" />
                </Button>
            </div>
        </Stack>
    );
}