import {
  Text,
  Container,
  ActionIcon,
  Group,
  rem,
  Center,
  Anchor,
  SimpleGrid,
  Divider,
  useMantineTheme,
} from "@mantine/core";
import {
  IconBrandYoutube,
  IconBrandDiscord,
} from "@tabler/icons-react";
import Logo from "../Logo/Logo";
import classes from "./FooterLinks.module.css";
import { Link } from "react-scroll";
import { Legal } from "../Legal/Legal";
import I18n from "../../i18n.js";

const data = [
  {
    title: "About",
    links: [
      { label: "menu.home", id: "home" },
      { label: "menu.features", id: "features" },
      { label: "menu.pricing", id: "pricing" },
    ],
  },
  {
    title: "Legal",
    links: [
      { label: "Mirecourtstr. 8", id: "#" },
      { label: "53225 Bonn", id: "#" },
      { label: "Niklas Krause", id: "#" },
      { label: "Discord: MrPig100", id: "#" },
    ],
  },
  {
    title: "Impressum",
    links: [
      { label: "", id: "imprint" },
      { label: "", id: "privacy" },
    ],
  },
];

export function FooterLinks() {
  const groups = data.map((group, groupIndex) => {
    const links = group.links.map((link, index) => {
      let linkElement;

      if (group.title === "About") {
        if(index === 0){
          linkElement = (
            <Link key={index} to="home" className={classes.link}>
              <Text>{I18n.t(link.label)}</Text>
            </Link>
          );
        } else {
          linkElement = (
            <Link key={index} to={link.id} className={classes.link}>
              <Text>{I18n.t(link.label)}</Text>
            </Link>
          );
        }
      } else if (group.title === "Impressum") {
        linkElement = (
          <Legal 
            key={index} 
            id={link.id}
            index={index}
          />
        );
      } else {
        linkElement = (
          <Text key={index} className={classes.nonLink}>
            {link.label}
          </Text>
        );
      }

      return linkElement;
    });
    return (
      <div className={classes.wrapper} key={groupIndex}>
        <Text className={classes.title}>{group.title}</Text>
        {links}
      </div>
    );
  });
  const theme = useMantineTheme();

  return (
    <Center >
      <footer className={classes.footer} style={{ maxWidth: theme.breakpoints.lg, width: '100%' }}>
        <SimpleGrid cols={{ base: 1, sm: 2, lg: 2 }} spacing={50} mt={30}>
          <Container className={classes.inner}>
            <div className={classes.logo}>
              <Logo />
              <Text size="xs" c="dimmed" className={classes.description}>
                {I18n.t("title.toolkit")}
              </Text>
            </div>
          </Container>

          

          <Container>
            <SimpleGrid
              style={{ right: "50%" }}
              cols={{ base: 1, sm: 3, lg: 3 }}
            >
              {groups}
            </SimpleGrid>
          </Container>
        </SimpleGrid>
        <Divider my={"sm"} />

        <Container className={classes.afterFooter}>
          <Text c="dimmed" size="sm">
            © 2024 ACC TV. All rights reserved.
          </Text>

          <Group
            gap={0}
            className={classes.social}
            justify="flex-end"
            wrap="nowrap"
          >
            <Anchor href="https://dsc.gg/ACCTV" target="_blank">
            <ActionIcon size="lg" color="gray" variant="subtle">
              <IconBrandDiscord
                style={{ width: rem(18), height: rem(18) }}
                stroke={1.5}
              />
            </ActionIcon>
            </Anchor>
            <Anchor href="https://www.youtube.com/channel/UCbvxTXYQOvln58MpErXZZIA" target="_blank">
            <ActionIcon size="lg" color="gray" variant="subtle">
              <IconBrandYoutube
                style={{ width: rem(18), height: rem(18) }}
                stroke={1.5}
              />
            </ActionIcon>
            </Anchor>
          </Group>
        </Container>
      </footer>
    </Center>
  );
}
