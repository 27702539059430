import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

export const MyLanguages = [
  { label: "English", code: "gb" },
  { label: "Deutsch", code: "de" },
];

export function GetInitialLanguage() {
  const browserLanguage = i18n.language || window.navigator.language;
  const normalizedLanguage = browserLanguage.split('-')[0]; // Extrahiert den ersten Teil, z.B. 'de' aus 'de-DE'
  const matchedLanguage = MyLanguages.find((lang) => lang.code === normalizedLanguage);
  return matchedLanguage || MyLanguages[0]; // Fallback to default language if no match
}
const version = new Date().getTime();
i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    whitelist: ["de", "gb"],
    debug: false,
    fallbackLng: "gb",
    // keySeparator: false,
    backend: {
      loadPath: `/locales/{{lng}}/translation.json?v=${version}`,
    },
    detection: {
      order: ['localStorage', 'navigator', 'htmlTag'],
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
