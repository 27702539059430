import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  createTheme,
  MantineColorsTuple,
  MantineProvider,
} from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import "./i18n";
import { Notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const accTVColors: MantineColorsTuple = [
  "#ff7f2a",
  "#ff751a",
  "#ff6600",
  "#e65c00",
  "#cc5200",
  "#fa802e",
  "#f48134",
  "#ef8239",
  "#ea833e",
  "#e48444",
];

const theme = createTheme({
  colors: {
    accTVColors,
  },
});

root.render(
  <React.StrictMode>
    <MantineProvider defaultColorScheme="dark" theme={theme}>
      <ModalsProvider>
        <Suspense fallback={<></>}>
          <Notifications />
          <Router>
            <Routes>
              <Route path="*" element={<App />} />
              <Route path="/impressum" element={<App />} />
            </Routes>
          </Router>
        </Suspense>
      </ModalsProvider>
    </MantineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
